export const prideHashtags = [
  "pride",
  "pridemonth",
  "loveislove",
  "lgbtpride",
  "lgbt",
  "lgbtq",
  "lgbtcommunity",
  "gay",
  "gaypride",
  "pride2024",
  "lesbian",
  "lesbianlove",
  "lesbianpride",
  "trans",
  "transgender",
  "lgbtqia",
  "transpride",
  "transwoman",
  "transman",
  "equality",
  "nonbinary",
  "dragqueen",
  "queer",
  "pansexual",
  "bisexual",
  "lovewins",
  "queerpride",
  "genderfluid",
  "agender",
  "asexual",
  "intersex",
  "homosexual",
  "aromantic",
  "demisexual",
  "genderqueer",
  "polysexual",
  "skoliosexual",
  "homoromantic",
  "biromantic",
  "panromantic",
  "transvisibility",
  "cisgender",
  "queervisibility",
  "transally",
  "pridewalk",
  "prideart",
  "pridehistory",
  "pridesupport",
  "prideallyear",
  "prideandjoy"
];
