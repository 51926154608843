export enum MainnetContracts {
  DefaultToken = "0x3d2bD0e15829AA5C362a4144FdF4A1112fa29B5c",
  HeyLensSignup = "0x4b8845ACb8148dE64D1D99Cf27A3890a91F55E53",
  HeyTipping = "0xCFeB907551c3C7E4Df2627B2Ed3D0C782702785B",
  LensHandles = "0xe7E7EaD361f3AaCD73A61A9bD6C10cA17F38E945",
  LensHub = "0xDb46d1Dc155634FbC732f92E853b10B288AD5a1d",
  PermissionlessCreator = "0x0b5e6100243f793e480DE6088dE6bA70aA9f3872",
  TokenHandleRegistry = "0xD4F2F33680FCCb36748FA9831851643781608844"
}

export enum TestnetContracts {
  DefaultToken = "0x3d2bD0e15829AA5C362a4144FdF4A1112fa29B5c",
  HeyLensSignup = "0x82Bcb5DA51c6F3D0Ce942bDBEbb0B8A7774d62e8",
  HeyTipping = "0xAadeC94DCD26555F464455d309a5E896f78cC65f",
  LensHandles = "0xf6fDD7932219D64f267E4BfaF8d19774526d31D9",
  LensHub = "0xA2574D9DdB6A325Ad2Be838Bd854228B80215148",
  PermissionlessCreator = "0x36440da1D98FF46637f0b98AAA082bc77977B49B",
  TokenHandleRegistry = "0x24360dc6Af3c0b37baA8B0aaDD5BcA11C1a1389A"
}
