export const PermissionlessCreator = [
  {
    inputs: [
      { internalType: "address", name: "targetAddress", type: "address" }
    ],
    name: "getCreditBalance",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  }
];
